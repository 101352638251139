var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100",
    staticStyle: {
      "background-image": "url(/images/header.png)"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-left"
  }, [_c('div', {
    staticClass: "col-lg-12 text-left"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h5', {
    staticClass: "text-white mt-4"
  }, [_vm._v(_vm._s(_vm.$t("navbar.hi")) + " " + _vm._s(_vm.currentUser.name) + ",")]), _c('h4', {
    staticClass: "title text-white m-0"
  }, [_vm._v(_vm._s(_vm.$t("welcometo-awaris")) + "!")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "card rounded border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 mb-4"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("choose-business")))])])]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": _vm.wasiatProcess
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.wasiat")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": function ($event) {
        return _vm.pindaan('Wasiat');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.confirmation")) + " Wasiat")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [[null, 5].includes(_vm.amendment_status) && _vm.userRole == 'Client' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": _vm.applyAmendment
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.apply-new")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Client' && this.amendment_status != null && !_vm.hasAnyHibah ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": _vm.statusPindaan
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.pindaan")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Client' && !_vm.hasAnyHibah ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": _vm.status
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.status")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Client' && !_vm.hasAnyHibah ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": _vm.sejarah
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.history")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Client' && _vm.hasAnyHibah ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('a', {
    staticClass: "btn btn-primary-db btn-block",
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.redirectToApi('hibah.list.client');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.my-grant")) + " ")])]) : _vm._e()]), !['production'].includes(_vm.environment) ? _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Client' && this.hibahType == 12 ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": function ($event) {
        return _vm.HibahOneCent(12);
      }
    }
  }, [_vm._v("Hibah One Cent")])]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": _vm.approval
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.user")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.preference.allow_wasiat && ['Super Admin', 'Admin', 'Staff'].includes(_vm.userRole) && _vm.orgCode != 'EZQ' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": _vm.viewGraph
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.list")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-lg-10 mb-2",
    on: {
      "click": function ($event) {
        return _vm.redirectToApi('hibah.pending.approval');
      }
    }
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.hibah")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block",
    on: {
      "click": function ($event) {
        return _vm.pindaan('Hibah');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.confirmation")) + " Hibah")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [['Staff', 'Admin'].includes(_vm.userRole) && _vm.preference.allow_wasiat == 1 ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_vm.userRole == 'Admin' && _vm.orgCode == 'TN' ? _c('router-link', {
    staticClass: "btn btn-primary-db btn-block",
    attrs: {
      "to": {
        name: 'tekun.upload.list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tekun.cert-upload")) + " ")]) : _vm._e(), _vm.userRole == 'Staff' ? _c('router-link', {
    staticClass: "btn btn-primary-db btn-block",
    attrs: {
      "to": {
        name: 'tekun.upload.list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("tekun.cert-verification")) + " ")]) : _vm._e()], 1) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Agent' && _vm.preference.allow_wasiat == 1 ? _c('div', {
    staticClass: "col-lg-10 mb-2",
    on: {
      "click": _vm.registerWasiat
    }
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.reg-will")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Agent' && _vm.preference.allow_hibah == 1 ? _c('div', {
    staticClass: "col-lg-10 mb-2",
    on: {
      "click": _vm.registerHibah
    }
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block"
  }, [_vm._v(" " + _vm._s(_vm.$t("dashboard.reg-hibah")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary-db btn-block",
    attrs: {
      "to": {
        name: 'ezqurban.transaction.list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ezq-tx")) + " ")])], 1) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'IT' ? _c('div', {
    staticClass: "col-lg-10 mb-2",
    on: {
      "click": _vm.TambahPengguna
    }
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block"
  }, [_vm._v(_vm._s(_vm.$t("org.add-user")))])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.userRole == 'IT' ? _c('div', {
    staticClass: "col-lg-10 mb-2",
    on: {
      "click": _vm.TambahCawangan
    }
  }, [_c('button', {
    staticClass: "btn btn-primary-db btn-block"
  }, [_vm._v(_vm._s(_vm.$t("org.add-branch")))])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.orgCode == 'EZQ' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary-db btn-block",
    attrs: {
      "tag": "button",
      "to": {
        name: 'ezqurban.transaction.list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("transaction")) + " ")])], 1) : _vm._e(), _vm.orgCode == 'EZQ' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary-db btn-block",
    attrs: {
      "tag": "button",
      "to": {
        name: 'ezqurban.fund-transfer.list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("fund-transfer")) + " ")])], 1) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_vm.orgCode == 'EZQ' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary-db btn-block",
    attrs: {
      "tag": "button",
      "to": {
        name: 'ezqurban.transaction.list'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("transaction")) + " ")])], 1) : _vm._e(), _vm.orgCode == 'GETB' ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('router-link', {
    staticClass: "tt-cap btn btn-primary-db btn-block",
    attrs: {
      "tag": "button",
      "to": {
        name: 'organization.promo-codes',
        params: {
          organization_id: this.userAccess.organization.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("promo-code")) + " ")])], 1) : _vm._e(), _vm.orgCode == 'BIMB' || _vm.bimbFilter() && _vm.hasAnyWasiat ? _c('div', {
    staticClass: "col-lg-10 mb-2"
  }, [_c('a', {
    staticClass: "tt-cap btn btn-primary-db btn-block",
    attrs: {
      "href": "https://awaris.sgp1.digitaloceanspaces.com/product-information/Wasiat_07032022%5B241630%5D.pdf",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("bimb.sheets-of-products")) + " ")])]) : _vm._e()])])])]), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "card bg-light rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("acc-information")))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative mt-4"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("name")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative mt-4"
  }, [_c('p', [_vm._v(": " + _vm._s(_vm.currentUser ? _vm.currentUser.name : "-"))])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('p', [_vm._v(" : " + _vm._s(_vm.currentUser.ic_number ? _vm.currentUser.ic_number : "-") + " ")])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("address")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('p', [_vm._v(": " + _vm._s(_vm.fullAddress ? _vm.fullAddress : "-"))])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mobile-no")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('p', [_vm._v(" : " + _vm._s(_vm.currentUser.mobile_number ? _vm.currentUser.mobile_number : "-") + " ")])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("email")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('p', [_vm._v(" : " + _vm._s(_vm.currentUser.email ? _vm.currentUser.email : "-") + " ")])])])]), _c('hr'), ['Staff', 'Super Admin'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("partner-name")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_vm.user_accesses && _vm.user_accesses.organization ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" : " + _vm._s(_vm.user_accesses.organization.name) + " ")]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_vm.organization_type == 'Awaris' ? _c('label', [_vm._v(_vm._s(_vm.$t("position")))]) : _vm._e(), _vm.organization_type != 'Awaris' ? _c('label', [_vm._v(_vm._s(_vm.$t("user-role")))]) : _vm._e()])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('p', [_vm._v(" : " + _vm._s(_vm.currentUser.roles ? _vm.currentUser.roles[0].name : "-") + " ")])])])]), ['Agent', 'Admin'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("org.nav2")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_vm.user_accesses && _vm.user_accesses.accessible ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" : " + _vm._s(_vm.user_accesses.accessible.branch_name) + " ")]) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("username")))])])]), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('p', [_vm._v(" : " + _vm._s(_vm.currentUser.username ? _vm.currentUser.username : "-") + " ")])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1), _c('b-modal', {
    ref: "modalStatus",
    attrs: {
      "title": "Status Wasiat",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "data-form"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("wasiat")))]), this.wasiat_status == null ? _c('label', [_vm._v(_vm._s(_vm.$t("wasiatmodal.status-null")))]) : _vm._e(), this.wasiat_status == 0 ? _c('label', [_vm._v(_vm._s(_vm.$t("wasiatmodal.status0")))]) : _vm._e(), this.wasiat_status == 1 ? _c('label', [_vm._v(_vm._s(_vm.$t("wasiatmodal.status1")))]) : _vm._e(), this.wasiat_status == 2 ? _c('label', [_vm._v(_vm._s(_vm.$t("wasiatmodal.status2")))]) : _vm._e(), this.wasiat_status == 3 ? _c('label', [_vm._v(_vm._s(_vm.$t("wasiatmodal.status3")))]) : _vm._e()]), _c('div', {
    staticClass: "data-form"
  }, [this.wasiat_status == 2 ? _c('button', {
    staticClass: "btn btn-success",
    on: {
      "click": _vm.printWasiat
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("printwill")) + " "), _vm.print ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e()])])]), _c('b-modal', {
    ref: "modalStatusPindaan",
    attrs: {
      "title": "Status Pindaan Wasiat",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [this.amendment_status != null ? _c('div', {
    staticClass: "data-form"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("amendments")))]), _c('label', [_vm._v(_vm._s(this.amendment_status == 0 ? this.$t("amendmentmodal.status0") : this.amendment_status == 1 ? this.$t("amendmentmodal.status1") : this.amendment_status == 2 ? this.$t("amendmentmodal.status3") : this.amendment_status == 4 ? this.$t("amendmentmodal.status4") : this.amendment_status == 5 ? this.$t("amendmentmodal.status2") : this.$t("amendmentmodal.status5")))])]) : _vm._e()])]), _c('b-modal', {
    ref: "payAmendment",
    attrs: {
      "title": "Jumlah Pembayaran",
      "no-enforce-focus": true,
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('label', {
    staticClass: "label-agree-term"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.$t("agree-with-payment")) + " ")])])]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('label', [_c('strong', [_vm._v("RM" + _vm._s(_vm.amendment_price))])])])])]), _c('b-modal', {
    ref: "sejarahModal",
    attrs: {
      "hide-footer": "",
      "title": "Sejarah Transaksi"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [this.payment_received_at == null ? _c('div', {
    staticClass: "data-form"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("wasiatmodal.no-payment")) + ".")])]) : _vm._e(), this.payment_received_at != null ? _c('div', {
    staticClass: "data-form"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("wasiatmodal.payment-done")) + ".")]), _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-4"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('th', [_vm._v(_vm._s(_vm.$t("date")))]), _c('th', [_vm._v(_vm._s(_vm.$t("total")))]), _c('th', [_vm._v(_vm._s(_vm.$t("information")))])]), _c('tr', [_c('td', [_vm._v(_vm._s(this.payment_received_at))]), _c('td', [_vm._v("RM " + _vm._s(this.price))]), _c('td', [_c('button', {
    staticClass: "btn-sm btn-primary m-1",
    on: {
      "click": _vm.printReceipt
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatmodal.send-receipt")) + " "), _vm.btnsejarah ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]), _c('br'), _c('br'), _c('router-link', {
    staticClass: "btn-sm btn-success m-1",
    attrs: {
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiat_id
        }
      },
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatmodal.download-receipt")) + " ")])], 1)])])])]) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }